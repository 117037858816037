import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from "./actionTypes"
import { apiError, loginSuccess, logoutUserSuccess } from "./actions"

//Include Both Helper File with needed methods






function* authSaga() {
  yield takeEvery(LOGIN_USER, )
  yield takeLatest(SOCIAL_LOGIN, )
  yield takeEvery(LOGOUT_USER, )
}

export default authSaga
