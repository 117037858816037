import React, { useState, useEffect } from "react"
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { connect } from "react-redux"
import {routes} from "../src/routes"
import VerticalLayout from "./components/VerticalLayout/"
import HorizontalLayout from "./components/HorizontalLayout/"
import "./assets/scss/theme.scss"
import Auth from "./pages/Authentication/Auth";
import Loader from "./SiteLoader/Loader";


const Login = React.lazy(()=> import('./pages/Authentication/Login'))

const App = props => {
  function getLayout() {
    let layoutCls = VerticalLayout

    switch (props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout
        break
      default:
        layoutCls = VerticalLayout
        break
    }
    return layoutCls
  }
  const Layout = getLayout()

const [accessData, setData] = useState([])

  const getAccessData = () => {
    const storedData = localStorage.getItem('Access');

    if (storedData) {
      setData(JSON.parse(storedData));
    }
  }

  useEffect(() => {
    getAccessData()
  }, []);
 

  return (
    <React.Suspense fallback={<Loader />}>
     
   <Routes>
        <Route path='/' element={<Login />}/>


        <Route path={`${process.env.PUBLIC_URL}/`} element={<Auth/> }>
          {routes.map((e, i) => (
            <Route key={i}>
              <Route path={`${process.env.PUBLIC_URL}/${e.path}`} element={<Layout>{e.component}</Layout>} />
            </Route>))
          }
          <Route path="*" element={<Navigate to={'/404page'} />} />
        </Route>

      </Routes>
    </React.Suspense>
  )
}



const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(App)