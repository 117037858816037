import { takeEvery, fork,  all,  } from "redux-saga/effects"

// Login Redux States
import { EDIT_PROFILE } from "./actionTypes"

//Include Both Helper File with needed methods





export function* watchProfile() {
  yield takeEvery(EDIT_PROFILE)
}

function* ProfileSaga() {
  yield all([fork(watchProfile)])
}

export default ProfileSaga
