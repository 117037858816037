import { takeEvery, fork, all } from "redux-saga/effects"

import { REGISTER_USER } from "./actionTypes"


//Include Both Helper File with needed methods



// initialize relavant method of both Auth


// Is user register successfull then direct plot user in redux.


export function* watchUserRegister() {
  yield takeEvery(REGISTER_USER, )
}

function* accountSaga() {
  yield all([fork(watchUserRegister)])
}

export default accountSaga
