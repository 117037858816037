import { useLocation } from "react-router-dom";


const Helpmate = {
  activeKey: function (date) {
    var res = window.location.pathname
    var baseUrl = process.env.PUBLIC_URL
    baseUrl = baseUrl.split("/");
    res = res.split("/");
    res = res.length > 0 ? res[baseUrl.length] : "/";
    res = res ? "/" + res : "/";;
    const activeKey1 = res;
    return activeKey1
  },


  toFixedFunction: function (inputValue, decimalValue) {

    let number = noExponents(inputValue)


    const decimal = decimalValue;
    const [integerPart, decimalPart] = number.toString().split('.');
    const truncatedDecimal = decimal > 0 ? decimalPart ? decimalPart.slice(0, decimal) : '' : decimalPart;

    let formattedNumber = decimal ? `${integerPart}.${truncatedDecimal}` : integerPart;

    let fixed = decimal;
    let num = formattedNumber;
    let Zero = "0";

    if (!num) {

      return `0.${"".padEnd(fixed, Zero)}`;
    }

    let splited = num.toString().split(".");

    if (splited.length > 1) {

      if (splited[1].length < fixed) {
        let Newvalue = fixed > 0 ? `${splited[0]}.${splited[1].padEnd(fixed, Zero)}` : num;
        num = Newvalue;
      } else {
        let Newvalue = fixed > 0 ? `${splited[0]}.${splited[1].slice(0, fixed)}` : num;
        num = Newvalue;
      }
    } else {

      if (decimal > 0) {
        let Newvalue = `${splited[0]}.${"".padEnd(fixed, Zero)}`;
        num = Newvalue;

      } else {
        num = num
      }

    }

    let result = num;
    if (!isNaN(result)) {

      return result;
    }
    else {
      return parseFloat(0).toFixed(decimal)
    }
  },

  decimal: (text, pointer = 8, type = 0) => {

    let val = text.target.value;
    let start = text.target.selectionStart;
    let pattern = /(\d{0,200})[^.]*((?:\.\d{0,200})?)/g
    let result = '';
    if (+(type) === 0 || +type === 1 || +type === 2) {
      val = val.replace(/([^0-9.]+)/, "");
      const match = pattern.exec(val);
      if (val.length > 0 && pointer > 0) {
        if (match[2] != '') {
          text.target.value = `${match[1]}.${match[2].replace('.', '').slice(0, pointer)}`
        }
        text.target.setSelectionRange(start, start);
        result = text.target.value
      }
      else {
        result = text.target.value.toString().replace(/\./gi, '')
        if (result && result.toString().split('.')[1] && result.toString().split('.')[1].length > 0) {
          result = `${result.toString().split('.')[0]}.${result.toString().split('.')[1].slice(0, 8)}`
        }
        return result
      }

      if (val.split('.')[0] === '' || val.split('.')[0].length > 1 && val.split('.')[0][0] === '0') {

        result = ''
      }
      return result;
    }
    else {
      val = val.replace(/([^0-9.]+)/, "");
      const match = pattern.exec(val);
      const value = match[1] + match[2];
      text.target.value = value;
      if (val.length > 0) {
        text.target.value = Number(value).toFixed(2);
        text.target.setSelectionRange(start, start);
        result = Number(value).toFixed(2);
      }
      return result;
    }
  },

  timeFormat: function (date) {
    date = new Date(date)
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var seconds = date.getSeconds();
    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    seconds = seconds < 10 ? '0' + seconds : seconds;
    return hours + ':' + minutes + ':' + seconds;
  },

  daymonthyearformat: function (date) {
    date = new Date(date);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var seconds = date.getSeconds();
    var dates = date.getDate();
    var months = date.getMonth() + 1;
    var years = date.getFullYear();
    var ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;
    dates = dates < 10 ? "0" + dates : dates;
    months = months < 10 ? "0" + months : months;
    var strTime = hours + ":" + minutes + ":" + seconds + " " + ampm;
    return dates + "/" + months + "/" + years + " " + strTime;
  },

  NumbersOnly: function (e) {
    if (e.keyCode === 8 || e.keyCode === 46) {
      return;
    }

    const isNumeric = /^[0-9]*$/; if (!isNumeric.test(e.key)) { e.preventDefault() }
  },

  AmountNumbers: function (e) {

    if (e.keyCode === 8 || e.keyCode === 46 || e.keyCode === 39 || e.keyCode === 37) {
      return;
    }

    const inputValue = e.target.value;

    if (inputValue === '' && e.key === '.') {
      e.preventDefault();
      return;
    }

    const isNumeric = /^[0-9]*\.?[0-9]*$/;

    if (!isNumeric.test(inputValue) || (inputValue.includes('.') && e.key === '.')) {
      e.preventDefault();

      if (e.key === '.' && inputValue.includes('.')) {
        return;
      }

      if (inputValue.includes('.')) {
        const modifiedValue = inputValue.replace('.', '');
        e.target.value = modifiedValue;
      }
    }
    if (!/^[0-9.]*$/.test(e.key)) {
      e.preventDefault();
    }
  },


  AlphaNumericOnly: function (e) {
    if (e.keyCode === 8 || e.keyCode === 46) {
      return;
    }

    const isAlphaNumeric = /^[0-9a-zA-Z]*$/; if (!isAlphaNumeric.test(e.key)) { e.preventDefault() }
  },

  AlbhabetsOnly: function (e) {
    if (e.keyCode === 8 || e.keyCode === 46) {
      return;
    }

    const isAlphaNumeric = /^[a-zA-Z]*$/; if (!isAlphaNumeric.test(e.key)) { e.preventDefault() }
  },

  LocalstorageRemove: function (e) {
    localStorage.removeItem(process.env.REACT_APP_LOGTOK)
  
  },

  isLogin: function () {

    let LoginToken = process.env.REACT_APP_LOGTOK

    let isChk = (localStorage.getItem(LoginToken)) ? true : false;

    return isChk;
  },

  getAdminAccess: (keyName) => {
    const accessDetails = localStorage.getItem('access') && JSON.parselocalStorage.getItem('access')
    const accessData = accessDetails && accessDetails.find(elem => (elem.access === keyName)) || {}
    const adminRole = process.env.REACT_APP_ADMINROLE
    const role =localStorage.getItem(adminRole) || 0
    const isMainAdmin = (+role === 1 || +role === 0) ? false : true

    const getIsAccess = (access) => {

      if (accessData && accessData.permission && +accessData.permission[access] === 1) {

        return true
      } else {
        return false
      }
    }

    return { isMainAdmin, getIsAccess }
  },
  EmailRegex: /^(?!.*?\.\..*?@)(?!.*?@\..*?\..*?$)[a-zA-Z0-9.!#$%&'+/=?^_`{|}~-]+@[A-Za-z0-9-]+\.[A-Za-z.]{2,}$/,

}


export default Helpmate


function noExponents(val) {
  var data = String(val).split(/[eE]/);
  if (+(data.length) === 1) return data[0];

  var z = '',
    sign = val < 0 ? '-' : '',
    str = data[0].replace('.', ''),
    mag = Number(data[1]) + 1;

  if (mag < 0) {
    z = sign + '0.';
    while (mag++) z += '0';
    return z + str.replace(/^\-/, '');
  }
  mag -= str.length;
  while (mag--) z += '0';
  return str + z;
}

export const AccessChecker = (BaseComponent, data) => {
  const obj = {
    'dashboard': 'dashboard',
  }

  return function EnhancedComponent(props) {

    const location = useLocation()

    const getIsAccess = (accessType = '', accessDetails = {}) => {

      var key = data
      if (obj[location.pathname.replace('/', '')]) {
        key = obj[location.pathname.replace('/', '')]
      } else {
        key = data
      }
      const result = accessDetails && accessDetails.access && accessDetails.access.find(elm => (elm.access === key));
      if (result && accessDetails && accessDetails.role !== 2) {
        if (accessDetails && +accessDetails.role === 1 || accessDetails && +accessDetails.role === 0) {
          if (result && result.permission && +result.permission[accessType] === 1) {
            return true;
          }
        }
      }
      if (accessDetails && +accessDetails.role === 2) {
        return true;
      }
      return false;
    }

    return (
      <BaseComponent {...props} getIsAccess={getIsAccess} />
    )
  }
}

export const AccessCheck =  (data,type,permission) => {

        let adminRole       = data.Role;
        let adminAccess     = data.Access;
        let adminPermission = data.Permission;

        let checkType       = type;
        let checkPermission = permission;

        if(adminRole == 0 ){
            return(true)
        }else{
          let AccessFilter = adminAccess?.includes(checkType)

            if(AccessFilter){
            let permissFilter = adminPermission.find(find => find.access == checkType)
            if(permissFilter){
                if(permissFilter?.permission[checkPermission] == 1){
                    return(true)
                }else{
                    return(false)
                }
            }else{
                return(false)
            }
            }else{
            return(false)

            }
          
        }

}

export const AccessCheckSideMenu = async (data,type) => {

  let adminRole       = data.role;
  let adminAccess     = data.access;

  let checkType       = type;

  if(adminRole == 0 ){
      return(true,1)
  }else{
    let AccessFilter = await adminAccess.includes(checkType)
      if(AccessFilter){
        return(true,0)
      }else{
      return(false,0)

      }
    
  }

}