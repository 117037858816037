import { MdDashboard } from "react-icons/md";
import { FaUsers } from "react-icons/fa";
import { CgProfile } from "react-icons/cg";
import { TbDeviceIpadMinus } from "react-icons/tb";
import {  FaHistory } from 'react-icons/fa';
import { FaSyncAlt } from 'react-icons/fa';
import { MdAdminPanelSettings } from "react-icons/md";
import { TfiAnnouncement } from "react-icons/tfi";
import { MdCompost } from "react-icons/md";
import { GiNewspaper } from "react-icons/gi";
import { GrGallery } from "react-icons/gr";


export const menuItems = [
    {
        icon: <MdDashboard />,
        link: "/dashboard",
        text: "Dashboard",
        key : "dashboard"
    },
    {
        icon: <FaUsers />,
        link: "/member",
        text: "Members",
        key : "memberList"

    },
    {
        icon: <MdCompost />,
        link: "/add",
        text: "Create Post",
        key : "post"

    },
    {
        icon: <CgProfile />,
        link: "/upload",
        text: "Post",
        key : "post"

    },
  
  
    {
        icon: <TfiAnnouncement />,
        link: "/announcement",
        text: "Announcement",
        key : "announcement"

    },
    {
        icon: <GiNewspaper />,
        link: "/news",
        text: "News",
        key : "socialMedia"

    },
    {
        icon: <GrGallery />,
        link: "/gallery",
        text: "Gallery",
        key : "gallery"

    },
    {
        icon: <GrGallery />,
        link: "/conference",
        text: "Conference",
        key : "conference"

    },
    {
        icon: <MdAdminPanelSettings />,
        link: "/admindetail",
        text: "Admin Details",
        key : "admin"

    },
    {
        icon: <TbDeviceIpadMinus />,
        link: "/adminlog",
        text: "Admin Log",
        key : "adminLog"

    },

    {
        icon: <FaSyncAlt />,
        link: "/updatelive",
        text: "Live Update",
        key: "LiveUpdate"

    },

    {
        icon: <FaHistory />,
        link: "/livehis",
        text: "Live History",
        key: "HistoryLive"

    },



];

export const accessMenuItems = [
    {
        icon: <MdDashboard />,
        link: "/dashboard",
        text: "Dashboard",
    },
    {
        icon: <FaUsers />,
        link: "/member",
        text: "Members",
    },
    {
        icon: <CgProfile />,
        link: "/upload",
        text: "Post",
    },
  
    {
        icon: <TfiAnnouncement />,
        link: "/announcement",
        text: "Announcement",
    },
    {
        icon: <GiNewspaper />,
        link: "/news",
        text: "News",
    },
    {
        icon: <GrGallery />,
        link: "/gallery",
        text: "Gallery",
    },
    {
        icon: <TbDeviceIpadMinus />,
        link: "/adminlog",
        text: "Admin Log",
    },



];

export const menuItemssub = [
    {
        icon: <MdDashboard />,
        link: "/dashboard",
        text: "Dashboard",
        pageAccess: ['Members count', 'Pending Members', 'Total Members', 'Active Members']
    },
    {
        icon: <FaUsers />,
        link: "/member",
        text: "Members",
        pageAccess: ['MEMBERS', 'Search View', 'view']
    },
    {
        icon: <CgProfile />,
        link: "/upload",
        text: "Post",
        pageAccess: ['userpost']
    },
    {
        icon: <MdAdminPanelSettings />,
        link: "/admindetail",
        text: "Admin Details",
        pageAccess: ['AdminDetails']
    },
    {
        icon: <TbDeviceIpadMinus />,
        link: "/adminlog",
        text: "Admin Log",
        pageAccess: ['AdminLog']
    },

];