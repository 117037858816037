import React from 'react';
import './SiteLoader.css'; // CSS file for styling

const Loader = () => {
    return (
        <div className="site-loader">
            <div className="loader-circle"></div>
        </div>
    );
}

export default Loader;