import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const accessKey = process.env.REACT_APP_LOGTOK


const Auth = () => {

    let isLogin = (localStorage.getItem(accessKey)) ? true : true;

    return (

        <div> {isLogin ? <Outlet /> : <Navigate to={`${process.env.PUBLIC_URL}/`} />} </div>

    );
};

export default Auth;