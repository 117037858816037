import {  fork,  all } from "redux-saga/effects"






function* forgetPasswordSaga() {
  yield all([fork()])
}

export default forgetPasswordSaga
