import { takeEvery, put, call } from "redux-saga/effects"

// Chat Redux States
import {
  GET_CHATS,
  GET_GROUPS,
  GET_MESSAGES,
  POST_ADD_MESSAGE,
} from "./actionTypes"


//Include Both Helper File with needed methodps






function* chatSaga() {
  yield takeEvery(GET_CHATS, )
  yield takeEvery(GET_GROUPS, )
  yield takeEvery(GET_MESSAGES, )
  yield takeEvery(POST_ADD_MESSAGE, )
}

export default chatSaga
