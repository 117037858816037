import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import SimpleBar from "simplebar-react";
import { menuItems } from "./Sidemenu";


const SidebarContent = (props) => {
  const ref = useRef();


  const [accessData, setData] = useState([])

  const getAccessData = () => {
    const storedData = localStorage.getItem('Access');

    if (storedData) {
      setData(JSON.parse(storedData));
    }
  }

  useEffect(() => {
    getAccessData()
  }, []);


  return (
    <React.Fragment>
      <SimpleBar ref={ref} className="vertical-simplebar">
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">Menu</li>
            {menuItems?.map((item, index) => (
              <li key={index}>
                {(accessData.Role == 0) ?
                <Link to={item.link} className="waves-effect">
                  {item.icon}
                  <span className="badge rounded-pill bg-info float-end"></span>
                  <span>{item?.text}</span>
                </Link> : (accessData && accessData?.Access?.includes(item?.key)) ? <Link to={item?.link} className="waves-effect">
                  {item?.icon}
                  <span className="badge rounded-pill bg-info float-end"></span>
                  <span>{item?.text}</span>
                </Link> : <></> }
                
              </li>
            ))}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

export default SidebarContent;
